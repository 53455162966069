import Center from '@dr-pam/common-components/Components/Misc/Center';
import React from 'react';
import { useRouteError } from 'react-router-dom';

export type ErrorHandlerProps = {
	className?: string;
};

export default function ErrorHandler(props: ErrorHandlerProps) {
	const { className } = props;
	const error = useRouteError();

	const handleGoBackClicked = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		window.history.back();
	};

	console.error(error);

	const errorEl = error instanceof Error ? error.message : `${error}`;

	return (
		<Center className={`ErrorHandler dark-bg h100 w100  ${className ?? ''}`}>
			<h1>Uh oh...</h1>
			<p>Something unexpected happened. The error was:</p>
			<pre>{errorEl}</pre>
			<p>
				This problem may be solved by hard-refreshing (<strong>CTRL + F5</strong> on Windows, or{' '}
				<strong>CMD + SHIFT + R</strong> on MacOS), or by clearing your browser data{' '}
				<a href="https://its.uiowa.edu/support/article/719" target="_blank" rel="noreferrer">
					(click here for instructions)
				</a>
				.
			</p>
			<p>
				If you continue to see this error, please email us at{' '}
				<a href="mailto:tom@pameladouglas.com.au">tom@pameladouglas.com.au</a> and include details leading up to
				this error and screenshots if possible.
			</p>
			<p>
				<a href="/" onClick={handleGoBackClicked}>
					Go back
				</a>
			</p>
		</Center>
	);
}
