import React, { useEffect } from 'react';
import { IconLogout } from '@tabler/icons-react';
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import Avatar from '@dr-pam/common-components/Components/User/Avatar';
import { useProgrammeProvider } from '../programme/ProgrammeProvider';
import Loading from '@dr-pam/common-components/Components/Misc/Loading';
import ProgrammeDropDown from './ProgrammeDropDown';
import { useRuntimeConfigProvider } from '@dr-pam/common-components/Config/RuntimeConfigProvider';
import RuntimeConfig from '../../config/RuntimeConfig';
import { PurchasedProgramme } from '@dr-pam/common-components/Models/NdcProgramme';
import { routes } from '../App';
import Logo from '../layout/Logo';
import type { User } from '@dr-pam/common-types/database';

export type NavBarProps = {
	className?: string;
};

export default function NavBar(props: NavBarProps) {
	const { className } = props;

	const { config } = useRuntimeConfigProvider<RuntimeConfig>();

	const location = useLocation();
	const matchedRoutes = matchRoutes(routes, location) ?? [];
	const currentRoute = matchedRoutes.length ? matchedRoutes[0] : null;

	const navigate = useNavigate();
	const { user } = useAuthenticatedUser<User>();
	const programmeProvider = useProgrammeProvider();

	useEffect(() => {
		if (currentRoute?.pathname.startsWith('/programme/')) {
			const programmeId = currentRoute.params.programmeId ?? currentRoute.params.id;
			const programme = programmeProvider.programmes?.find((x) => x.id === programmeId);
			if (programme) {
				programmeProvider.setSelectedProgramme(programme);
			}
		}
	}, [currentRoute, programmeProvider]);

	const handleProgrammeChanged = (programme?: PurchasedProgramme) => {
		programmeProvider.setSelectedProgramme(programme);
		if (programme) {
			navigate(`/programme/${programme.id}`);
		}
	};

	let courseLinks = null;

	if (programmeProvider.isLoading) {
		courseLinks = (
			<div>
				<Loading className="secondary" />
			</div>
		);
	} else if (programmeProvider.programmes) {
		if (programmeProvider.programmes.length === 1) {
			const programme = programmeProvider.programmes[0];
			courseLinks = <Link to={`/programme/${programme.id}`}>{programme.name}</Link>;
		} else if (programmeProvider.programmes.length > 1) {
			const filteredProgrammes = programmeProvider.programmes
				.filter((x) => !x.isHidden)
				.sort((a, b) => a.name.localeCompare(b.name));
			courseLinks = (
				<ProgrammeDropDown
					programmes={filteredProgrammes}
					value={programmeProvider.selectedProgramme}
					onChange={handleProgrammeChanged}
				/>
			);
		}
	}

	return (
		<div className={`NavBar flex c dark-bg fg0 fs0 h100 ${className ?? ''}`}>
			<Link to="/" className="logo">
				<Logo />
			</Link>
			{courseLinks}
			<ul>
				{programmeProvider.programmes?.find((x) => x.id === config?.resourceHubProgrammeId) ? (
					<li>
						<Link to="/resource-hub">Resource hub</Link>
					</li>
				) : null}
				<li>
					<Link to="/activity-log">Activity log</Link>
				</li>
			</ul>
			<Link to="/">NDC courses</Link>
			<div className="account">
				<Link to="/profile">
					<Avatar url={user?.photoUrl ?? undefined} color="secondary" name="My account" />
				</Link>
				<Link to="/logout" title="Logout" className="flex">
					<IconLogout stroke={1} />
				</Link>
			</div>
		</div>
	);
}
