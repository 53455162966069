import React, { useEffect } from 'react';
import LoginForm, { LoginFormType } from '@dr-pam/common-components/Components/Auth/LoginForm';
import Center from '@dr-pam/common-components/Components/Misc/Center';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useToastProvider } from '@dr-pam/common-components/Components/Toast/ToastProvider';
import { useRuntimeConfigProvider } from '@dr-pam/common-components/Config/RuntimeConfigProvider';
import RuntimeConfig from '../config/RuntimeConfig';
import Loading from '@dr-pam/common-components/Components/Misc/Loading';
import Logo from '../components/layout/Logo';
import { useAuthService } from '@dr-pam/common-components/Components/Auth/AuthProvider';

export type LoginPageProps = {
	className?: string;
};

export default function LoginPage(props: LoginPageProps) {
	const { className } = props;

	const [params] = useSearchParams();
	const email = params.get('email') ?? undefined;

	const location = useLocation();
	const navigate = useNavigate();

	const authService = useAuthService();
	const { user, isLoading } = useAuthenticatedUser();
	const toastProvider = useToastProvider();
	const { config, isLoading: isConfigLoading } = useRuntimeConfigProvider<RuntimeConfig>();

	const redirectTo = location.state?.redirectTo ?? '/';

	useEffect(() => {
		if (user && !isLoading) {
			navigate(redirectTo);
		}
	}, [isLoading, navigate, redirectTo, user]);

	const onLoginRequested = async (form: LoginFormType) => {
		const user = await authService.signIn(form.email, form.password);
		if (user) {
			return true;
		} else {
			toastProvider.showToast({
				title: 'Login failed',
				children: 'Invalid email or password',
				color: 'error',
				autoCloseMs: 5000,
			});
			return false;
		}
	};

	return (
		<Center className={`LoginPage dark-bg h100 w100 ${className ?? ''}`}>
			{isConfigLoading ? (
				<Loading />
			) : (
				<>
					<Link to={config?.ndcInstituteUrl ?? '/'}>
						<Logo className="logo" />
					</Link>
					<LoginForm onSubmit={onLoginRequested} email={email}></LoginForm>
					<p className="additional-options tac">
						<Link to="/forgot-password">Forgot password</Link>
						<br />
						<a href={`${config?.ndcInstituteUrl ?? ''}/sign-up`}>Don&apos;t have an account? Sign up</a>
					</p>
				</>
			)}
		</Center>
	);
}
